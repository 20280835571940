import { Box, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import { assistance } from "../../data/assistance";
import GradeIcon from '@mui/icons-material/Grade';
import theme from "../../data/theme";


export default function Assistance(){
    return <Box sx={{p: {xs: 3, sm: 5, md: 7}}}>
        <Box sx={{ width: {xs: "100%", sm: "90%", md: "80%"}, m: "auto"}}>
        <Typography variant="h4" sx={{my: 3, fontWeight: 600}}>How do we help students?</Typography>
        <Box sx={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
            {assistance.map((item, index)=>{
                return <Paper sx={{textAlign: "left", bgcolor: theme.secondary, p: 3, my: 2, color: "white", borderRadius: "25px", width: {sm: "100%", md: "48%"}}} key={index}>
                    <Typography variant="h5" sx={{my: 2, fontWeight: 600, textAlign: "center"}}>{item.title}</Typography>
                    <Typography sx={{fontSize: "1.2rem", textAlign: "center"}}>{item.body}</Typography>
                    <Typography variant="h6" sx={{my: 2, fontSize: "1.4rem", textDecoration: "underline", textAlign: "center"}}>Offerings: </Typography>
                    {item.offerings.map((item, index)=><List key={index} sx={{textAlign:"center"}}>
                        <ListItem sx={{m:0, p:0, marginLeft: 2}}>
                            <ListItemIcon>
                                <GradeIcon/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography sx={{fontSize: "1.2rem"}}>{item}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>)}
                </Paper>
            })}
        </Box>
    </Box>
    </Box>
}