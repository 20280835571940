import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { country } from "../../data/country";

type countryProp = {
  country: country;
}

export default function CountryCard({country}:countryProp){
    return (
    <Card sx={{ minWidth: 245, maxWidth: 300, m: 2 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          image={country.image}
          alt={country.name}
        />
        <Box sx={{height: "70px", width: "70px", m: "auto", position: "relative", top: "-35px", borderRadius: "50%", border: "2px solid white"}}>
          <img
          height="100%"
          width="100%"
          style={{borderRadius: "50%"}}
          src={country.flag}
          alt={country.name+"flag"}/>
        </Box>
        
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {country.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {country.content}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}