import { Box, Paper, Typography } from "@mui/material";
import theme from "../../data/theme";

const services = [
  {
    title: "Counselling",
    body: "Guidance from the experts through out your overseas education admission process emotionally and strategically to retain your motivation, focus and overcome the academic, social and emotional challenges came across."
  },
  {
    title: "Admission & Application",
    body: "Professional support from the point of researching and shortlisting the universities that is suitable for your profile and developing the application following the requirements of the specific university to boost your admission chances.",
  },
  {
    title: "Visa Processing",
    body: "VISA processing involves everything from providing the list of required documents, assistance in filling the forms, document submission to interview preparation to make you to attend the interview with confidence.",
  }

];


export default function Services(){
    return <Box sx={{p: 3, bgcolor: theme.primary}} id="SERVICES">
            <Box sx={{width: {xs: "100%", sm: "90%", md:"80%"}, mx:"auto"}}>
              <Typography variant="h3" sx={{textAlign: "center", color: "white", my: 4 }}>Services</Typography>
            <Box
              sx={{
                borderRadius: 2,
                display: 'grid',
                gridTemplateColumns: {sx: '1fr 1fr'  ,md: '1fr 1fr 1fr' },
                gap: 2
              }}
            >

              {services.map((item, index)=>(
                <Paper key={index} elevation={2} sx={{p:4, bgcolor: theme.secondary, color: "white"}}>
                  <Typography variant="h5" sx={{fontWeight: "600", my:2, fontSize: "1.6rem", color: "white", textDecoration: "underline"}}>
                    {item.title}
                  </Typography>
                  <Typography sx={{textAlign:"justify", fontSize: "1.2rem", wordBreak: "break-all"}}>
                    {item.body}
                  </Typography>
                </Paper>
              ))}

            </Box>
            </Box>
          </Box>
}