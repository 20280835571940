import { Box, Typography } from "@mui/material";
import Form from "../Form/Form";
import { contactDetails } from "../../data/contactDetails";
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

export default function Footer(){
    return <Box sx={{bgcolor: "black"}} id="CONTACT">
        <Box sx={{width: {xs: "100%", sm: "90%", md: "80%"}, display: "flex", color:"white", py: 5, m: "auto", flexDirection: {xs: "column", md: "row"}}}>
            <Box sx={{py: 2, flex:1, textAlign: "left", borderRight: {sm: "none", md: "1px solid white"}, borderBottom: {xs: "1px solid white", md: "none"}, alignItems: "center", display:"flex", flexDirection: "column"}}>
                <Typography variant="h6" sx={{my: 2, fontSize: "1.5rem", textDecoration: "underline"}}>Contact Details</Typography>
                <Typography sx={{my: 1, fontSize: "1.2rem", display: "flex", alignItems:"center"}}><EmailIcon sx={{marginRight: 1}}/> Email: {contactDetails.email}</Typography>
                <Typography sx={{my: 1, fontSize: "1.2rem", display: "flex", alignItems:"center"}}><PhoneAndroidIcon sx={{marginRight: 1}}/> Phone: {contactDetails.phoneNo}</Typography>
            </Box>
            <Box sx={{flex:1, textAlign: "left"}}>
                <Form/>          
            </Box>
        </Box>
    </Box>
}