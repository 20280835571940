const about = [
    {
        title: "Guiding You Towards a World-Class Education and a Brighter Future.",
        body: "We at Radin overseas education follows strategic approach to leverage the profile strength and boost the admission to support the students who wish to study in a foreign country. These services includes guidance in every aspect throughout the application process, from choosing a university or college to successful VISA grant Needs to be focused on the points that makes you stand out from the competition Such as Limited Resource Intake."
    },
    {
        title: "Universities and Programs",
        body: "Get into top universities and colleges worldwide into the programs you are passionate that have enough accreditation to support you to achieve your academic and career aspirations. We at Radin help you to research thoroughly from countries, universities programs, visa requirements to financial aid opportunities to shortlist the options that fits your candidacy with high acceptancy.",
    },
    {
        title: "Consult with educational counsellors",
        body: "Consult with educational counsellors or study abroad advisors, to get ready ensure a successful transition. Additionally, consider cultural immersion programs to maximise your experience and make your initial days of abroad education journey more easier."
    }

]

export default about;