import { Box, Button, Typography } from "@mui/material";
import styles from "./Form.module.css";

export default function Form(){

    return <form>
        <Typography variant="h6" sx={{my: 2, textAlign: "center", textDecoration: "underline"}}>Register Here:</Typography>

        <div className={styles.inputContainer}>
            <input placeholder="Name" />
            <br/>
            <input placeholder="Email" />
            <br/>
            <input placeholder="Contact Number" />
        </div>

        <Box sx={{textAlign: "center", my: 2}}>
            <Button variant="contained" type="submit">Submit</Button>
        </Box>

    </form>;
}