import { Box, Typography } from "@mui/material";
import countries from "../../data/country";
import CountryCard from "../Card/CountryCard";


export default function Country(){
    return <Box sx={{m: 4, width: {xs: "100%", sm: "90%", md: "80%"}, mx: "auto"}}>
        <Typography variant="h4" sx={{fontWeight: "600"}}>For Your Abroad Study</Typography>
        <Typography variant="h5" sx={{fontWeight: "600"}}>Choose Country !</Typography>
        <Box sx={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
            {countries.map((country, index)=><CountryCard key={index} country={country}/>)}
        </Box>
    </Box>
}