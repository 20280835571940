const procedures = [
    {
        title: "Registration",
        body: "Do some initial research on different study destinations, universities, and programs that interest you. This will help you to have a basic understanding of the opportunities available to you.",
    },
    {
        title: "Meet with the consultant",
        body: "Schedule a meeting with the overseas education consultant to discuss your plans to study abroad. This can be done by either meeting in person, over the phone, or via email.",
    },
    {
        title: "Assessment",
        body: "The consultant will assess your educational background, career goals, and financial situation to recommend suitable study destinations, universities, and programs.",
    },
    {
        title: "Application process",
        body: "The consultant will guide you through the application process, including filling out application forms, writing essays, obtaining transcripts and recommendation letters, and submitting them to the universities.",
    },
    {
        title: "Financial assistance",
        body: "Consult with our financial aid offices. Scholarships, grants & loans are available for students seeking financial assistance for studying abroad.",
    },
    {
        title: "Visa assistance",
        body: "We can provide guidance on visa types, application requirements, and assist with completing the application process for studying or working abroad.",
    },
    {
        title: "Pre-departure orientation",
        body: "The consultant will provide you with pre-departure orientation, including information on culture shock, packing, travel arrangements, and accommodation.",
    },
    {
        title: "Follow-up",
        body: "The consultant will follow up with you while you are studying abroad to ensure that you are adapting well and to provide any necessary support.",
    },
]

export default procedures;