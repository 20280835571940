import { Box, Typography } from "@mui/material";
import procedures from "../../data/procedure";
import theme from "../../data/theme";


export default function Procedures(){
    return <Box sx={{bgcolor: "black", p: 3, color: "white"}}>
        <Box sx={{width: {xs: "100%", sm:"90%", md: "80%"}, m:"auto"}}>
            <Typography variant="h2" sx={{fontSize: "2rem", fontWeight: "600", textAlign: "center", my: 4}}>
                Our Step-By-Step Procedure To Bring Your Dream Into Reality
            </Typography>
            <Box sx={{textAlign: "left"}}>
                {procedures.map((item, index)=><Box key={index} sx={{my: 2, p: 5, bgcolor: theme.primary, color: "white", borderRadius: 5}}>
                        <Typography variant="h5" sx={{fontSize: "1.6rem", my:2, fontWeight: 600, textAlign: "center", textDecoration: "underline"}}>{item.title}</Typography>
                        <Typography sx={{fontSize: "1.2rem", textAlign: "center"}}>{item.body}</Typography>
                    </Box>)
                }

            </Box>        
        </Box>
        
    </Box>
}