
export type country = {
    name:string
    flag: string
    image: string
    content: string
}

const countries : country[] = [
    {
        name: "United Kingdom",
        flag: "https://cdn.countryflags.com/thumbs/united-kingdom/flag-square-250.png",
        image: "https://images.pexels.com/photos/672532/pexels-photo-672532.jpeg?auto=compress&cs=tinysrgb&w=600",
        content: ""
    },
    {
        name: "Australia",
        flag: "https://cdn.countryflags.com/thumbs/australia/flag-square-250.png",
        image: "https://images.pexels.com/photos/2193300/pexels-photo-2193300.jpeg?auto=compress&cs=tinysrgb&w=600",
        content: ""
    },
    {
        name: "Canada",
        flag: "https://cdn.countryflags.com/thumbs/canada/flag-square-250.png",
        image: "https://images.pexels.com/photos/1519088/pexels-photo-1519088.jpeg?auto=compress&cs=tinysrgb&w=600",
        content: ""
    },
    {
        name: "United States",
        flag: "https://cdn.countryflags.com/thumbs/united-states-of-america/flag-square-250.png",
        image: "https://images.pexels.com/photos/208745/pexels-photo-208745.jpeg?auto=compress&cs=tinysrgb&w=600",
        content: ""
    },
    {
        name: "Ireland",
        flag: "https://cdn.countryflags.com/thumbs/ireland/flag-square-250.png",
        image: "https://images.pexels.com/photos/3566187/pexels-photo-3566187.jpeg?auto=compress&cs=tinysrgb&w=600",
        content: ""
    },
    {
        name: "New Zealand",
        flag: "https://cdn.countryflags.com/thumbs/new-zealand/flag-square-250.png",
        image: "https://images.pexels.com/photos/421927/pexels-photo-421927.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        content: ""
    }
]

export default countries;