export const assistance = [
    {
        title: "Counselling",
        body: "Our abroad studies counsellor is a professional who provides guidance and support to students who are planning to study abroad.",
        offerings: [
            "Providing information", 
            "Advising on applications", 
            "Assisting with visas",
            "Offering financial aid advice",
            "Providing pre-departure support",
            "Offering ongoing support"
        ],
    },
    {
        title: "Application assistance",
        body: "These services provide guidance on the application process, including requirements, deadlines, and necessary documents.",
        offerings: [
            "Advising on application requirements","Editing and reviewing applications","Researching universities",
            "Providing guidance on standardized tests", 
            "Helping with the visa process"
        ],
    },
    {
        title: "Choice of Course",
        body: "Choosing a course in the USA can be an exciting yet challenging process.",
        offerings: [
            "Identify your interests and career goals",
            "Research universities",
            "Check admission requirements",
            "Consider the cost",
            "Consult with an advisor",
            "Take advantage of online resources"
        ],
    },
    {
        title: "Admissions and applications",
        body: "Admissions and applications for abroad studies can be a complex and time-consuming process.",
        offerings: [
            "Research universities and courses",
            "Check admission requirements",
            "Prepare your application materials",
            "Submit your applications",
            "Apply for financial aid",
            "Check visa requirements"
        ],
    },
    {
        title: "Scholarship",
        body: "There are many scholarship opportunities available for students who wish to study at universities in the USA.",
        offerings: [
            "Merit-based scholarships",
            "Need-based scholarships",
            "International student scholarships",
            "Subject-specific scholarships",
            "Diversity scholarships"
        ],
    },
    {
        title: "Visa Processing",
        body: "Truman can provide you with detailed information on the visa requirements for studying in the country you have chosen.",
        offerings: [
            "Guidance on visa requirements",
            "Assistance with visa application forms",
            "Assistance with supporting documents",
            "Preparation for visa interviews",
            "Follow-up with the visa office"
        ],
    }
];