import { Box, Typography } from "@mui/material";
import about from "../../data/about";



export default function About(){    
    return <Box id="ABOUT" sx={{py: 4, bgcolor: "white"}}>
        <Box sx={{width: "80%", textAlign: "justify", mx: "auto", color: "black"}}>
            <Typography variant="h3" sx={{my: 3, fontSize: "2rem", fontWeight: 600}}>About Us</Typography>
                {about.map((item, index)=>{
                    return <Box key={index}>
                        <Typography variant="h6" sx={{my:3, fontWeight: 600, fontSize: "1.4rem"}}>{item.title}</Typography>
                        <Typography sx={{fontSize: "1.2rem"}}>{item.body}</Typography>
                    </Box>
                })}
        </Box>
        
    </Box>
}