import { Box, Button, Typography } from "@mui/material";
import theme from "../../data/theme";


export default function Landing(){
    return <Box id={"HOME"} sx={{p:{xs: 1, sm: 3, md: 7}, bgcolor: theme.primary, minHeight: "85vh" }}>
        <Box sx={{width: {xs: "100%", sm: "95%", lg: "80%"}, display: "flex", height: "100%", m:"auto", flexDirection: {xs: "column", md: "row"}}}>
            <Box sx={{p:3, flex: 1, color: "white", textAlign:"left", height: "100%", display:"flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "flex-start", paddingTop: 8}}>
                <Typography variant="h2" sx={{color: "white", textAlign: "left", my: 3, fontWeight: 800}}>Turn Your Dream Reality</Typography>
                <Typography variant="h5" sx={{color: "white", textAlign: "left", my: 2, fontWeight: 600}}>Radin Overseas Education guides you from course selection to university enrollment, helping you at every step of your journey.</Typography>
                <a href="#CONTACT">
                    <Button sx={{bgcolor: theme.secondary, textAlign:"left", my: 2, fontSize: "1.4rem", p:1, fontWeight: 600, color: "white"}}>Join Us</Button>
                </a>
            </Box>
            <Box sx={{p:3, flex: 1, display:"flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <img style={{maxWidth: "100%", borderRadius: "10px"}} src="images/students.jpg" alt="graduation"/>
            </Box>
        </Box>
    </Box>
}